import { AppRoutes } from "app-constants/AppRoutes";
import { checkPermissionList } from "helpers/permissions";
import { getSecretKey } from "helpers/storage";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuTop from "../../components/MenuTop/MenuTop";
import AUTH_ACTION from "../../redux/user/actions";
import MenuLeft from "./MenuLeft";
import MENU_LEFT_CONFIG from "./constants";

const filterMenu = (menu, condition) => {
  return menu.reduce((acc, item) => {
    if (item.module) {
      if (condition(item)) {
        acc.push(item);
      }
    } else {
      if (item.children && item.children.length > 0) {
        const filteredChildren = filterMenu(item.children, condition);
        if (filteredChildren.length > 0) {
          acc.push({ ...item, children: filteredChildren });
        }
      }
    }
    return acc;
  }, []);
};

const ConfigLayout = ({ children }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user?.infoUser);
  const history = useHistory();
  const userPermissions = useSelector(
    (state) => state.user?.infoUser?.permissions
  );
  const [menuDisplay, setMenuDisplay] = useState([]);

  useEffect(() => {
    if (!userInfo) {
      const secretKey = getSecretKey();
      if (secretKey) {
        dispatch(AUTH_ACTION.getInfoUser());
      } else {
        history.push(AppRoutes.login);
      }
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (userInfo) {
      const condition = (node) =>
        checkPermissionList(
          userPermissions,
          node.module.code,
          node.module.permissions
        );
      const filteredTreeArray = filterMenu(MENU_LEFT_CONFIG, condition);
      setMenuDisplay(filteredTreeArray);
    }
  }, [userInfo, userPermissions]);

  return userInfo ? (
    <div className="">
      <MenuTop />
      <MenuLeft
        menus={menuDisplay}
        isShowTitle={true}
        classNameContent="overflow-hidden"
      >
        {children}
      </MenuLeft>
    </div>
  ) : null;
};

ConfigLayout.propTypes = {
  children: PropTypes.node,
};

export default ConfigLayout;
