import { ChevronRight } from "@mui/icons-material";
import classNames from "classnames";
import useActiveMenu from "hooks/useActiveMenu";
import { getParentId } from "layouts/ConfigLayout/functions";
import { useCallback, useState } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

function MenuItem({ data, collapseMenu, className, menuActive, parentId }) {
  const [isExpanded, setExpanded] = useState(false);

  const params = useParams();
  const history = useHistory();

  const paramArray = Object.keys(params).map((key) => params[key]);
  const [...p] = paramArray;
  const onChangeItem = (link) => {
    if (typeof link === "function" && paramArray?.length) {
      const url = link(...p);
      history.push(url);
    } else {
      history.push(link);
    }
  };

  const { t } = useTranslation();
  const isActiveParent = parentId === data?.id;
  const render = useCallback(
    (data) => {
      switch (data?.type) {
        case "collapse":
          return (
            <div>
              <div
                key={data?.id}
                onClick={() => setExpanded(!isExpanded)}
                className={classNames(
                  "flex items-center gap-12px cursor-pointer cyan relative item-menu-left",
                  {
                    "font-medium": menuActive === data?.id,
                    "px-16px pt-16px pb-12px": true,
                    active: isActiveParent,
                  }
                )}
              >
                <div
                  className={classNames(
                    "absolute transform top-1/2 -translate-y-1/2 transition-all",
                    {
                      "right-3": !collapseMenu,
                      "right-0": collapseMenu,
                    }
                  )}
                >
                  <ChevronRight
                    className={classNames(
                      "text-20px transform transition-all",
                      {
                        "rotate-90": isExpanded,
                        "rotate-0": !isExpanded,
                      }
                    )}
                  />
                </div>

                <div
                  className={classNames(
                    "w-24px h-24px overflow-hidden",
                    {
                      "text-red-100": isActiveParent,
                    }
                  )}
                >
                  {data?.icon}
                </div>
                {!collapseMenu && (
                  <span
                    className={classNames(
                      "text-black opacity-85",
                      {
                        "text-red-100": isActiveParent,
                      }
                    )}
                  >
                    {t(data?.title)}
                  </span>
                )}
              </div>
              {!collapseMenu && (
                <Collapse isOpened={isExpanded} className="">
                  {data?.children?.map((item, index) => {
                    return (
                      <div key={index} className="pl-3">
                        <MenuItem
                          data={item}
                          collapseMenu={collapseMenu}
                          className="border-l border-D9D9D9 border-opacity-60"
                          menuActive={menuActive}
                        />
                      </div>
                    );
                  })}
                </Collapse>
              )}
            </div>
          );
        case "item":
          if (data?.link === null && !collapseMenu) {
            return (
              <div
                className={classNames("", {
                  "px-16px pt-16px pb-12px": true,
                })}
                key={data?.id}
              >
                <span className="uppercase text-12px text-black opacity-85 font-medium">
                  {t(data?.title)}
                </span>
              </div>
            );
          } else {
            return (
              <div
                key={data?.id}
                onClick={() => onChangeItem(data?.link)}
                className={classNames(
                  "flex items-center gap-12px cursor-pointer item-menu-left cyan",
                  {
                    "active font-medium": menuActive === data?.id,
                    "px-16px pt-16px pb-12px": true,
                  }
                )}
              >
                <div className="w-24px h-24px overflow-hidden">
                  {data?.icon}
                </div>
                {!collapseMenu && (
                  <span className="text-black opacity-85">
                    {t(data?.title)}
                  </span>
                )}
              </div>
            );
          }

        default:
          break;
      }
    },
    [isExpanded, collapseMenu, menuActive, t]
  );

  return <div className={className}>{render(data)}</div>;
}

const Menu = ({ menu = [], collapseMenu }) => {
  const menuActive = useActiveMenu();
  const parentId = getParentId(menuActive, menu);
  return (
    <div className="select-none">
      {menu.map((item, index) => {
        return (
          <MenuItem
            key={index}
            data={item}
            collapseMenu={collapseMenu}
            menuActive={menuActive}
            parentId={parentId}
          />
        );
      })}
    </div>
  );
};

export default Menu;

export const MENU_IDS = {
  menuAccount: "menuAccount",
  menuInfoUser: "menuInfoUser",
  menuAccounSecure: "menuAccounSecure",
  menuLanguage: "menuLanguage",
  menuOtherConfig: "menuOtherConfig",
  menuSmsGateway: "menuSmsGateway",
  menuMail: "menuMail",
  menuVip: "menuVip",
  menudRealEstate: "menudRealEstate",
  menuProjectOverview: "projectOverview",
  menuProjectProperty: "projectProperty",
  menuProjectResident: "projectResident",
  menuProjectDepartment: "projectDepartment",
  menuProjectEmployee: "projectEmployee",
  menuProjectInformation: "projectInformation",
  menuProjectStructure: "projectStructure",
  menuProjectPropertyOverview: "projectPropertyOverview",
  menuProjectPropertyHistory: "projectPropertyHistory",
  // VMG
  menuCategory: "menuCategory",
  menuVideo: "menuVideo",
  menuVideoGroup: "menuVideoGroup",
  menuBanner: "menuBanner",
  menuPopup: "menuPopup",
  menuArtist: "menuArtist",
  menuArtistPhoto: "menuArtistPhoto",
  menuArtistPost: "menuArtistPost",
  menuArtistReward: "menuArtistReward",
  menuPresentArtist: "menuPresentArtist",
  menuPresentAudio: "menuPresentAudio",
  menuPresentVideo: "menuPresentVideo",
  menuArtistPresentReport: "menuArtistPresentReport",
  menuProducts: "menuProducts",
  menuProductCategory: "menuProductCategory",
  menuEmojie: "menuEmojie",
  menuGifts: "menuGifts",
  menuGreetingCardLog: "menuGreetingCardLog",
  menuEventTicket: "menuEventTicket",
  menuEventArtist: "menuEventArtist",
  menuEvent: "menuEvent",
  menuOrder: "menuOrder",
  menuOrderProduct: "menuOrderProduct",
  menuPaymentType: "menuPaymentType",
  menuShippingUnit: "menuShippingUnit",
  menuArtistsProductCategory: "menuArtistsProductCategory",
  menuAlbum: "menuAlbum",
  menuAlbumVideo: "menuAlbumVideo",
  menuAudio: "menuAudio",
  menuConfigSystem: "menuConfigSystem",
  menuConfigHome: "menuConfigHome",
  menuUser: "menuUser",
  permission: "permission",
  permissionGroup: "permissionGroup",
  permissionGroupMember: "permissionGroupMember",
  menuUserDetail: "menuUserDetail",
  menuDashboard: "menuDashboard",
  menuAccountSystem: "menuAccountSystem",
  menuService: "menuService",
  menuPremiumType: "menuPremiumType",
  menuNotification: "menuNotification",
  menuTopic: "menuTopic",
  menuTag: "menuTag",
  menuStatistics: "menuStatistics",
  menuStatisticsArtists: "menuStatisticsArtists",
  menuStatisticsSystem: "menuStatisticsSystem",
  menuStatisticsPayment: "menuStatisticsPayment",
  menuHistoryPremium: "menuHistoryPremium",
  menuHistoryUsedCoin: "menuHistoryUsedCoin",
  menuVideoReport: "menuVideoReport",
  menuAudioReport: "menuAudioReport",
  menuPublisher: "menuPublisher",
  menuAuditLogs: "menuAuditLogs",
  menuLogSMShistory: "menuLogSMShistory",
};
