import { AppRoutes } from "app-constants/AppRoutes";
import { MENU_IDS } from "app-constants/menu";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function useActiveMenu() {
  const history = useHistory();
  const [menuActive, setMenuActive] = useState("");

  useEffect(() => {
    const getMenuActive = () => {
      let activeItem = "";
      const pathLocation = history.location.pathname;
      switch (pathLocation) {
        case AppRoutes.category:
          activeItem = MENU_IDS.menuCategory;
          break;
        case AppRoutes.permission:
          activeItem = MENU_IDS.permission;
          break;
        case AppRoutes.permission_group:
          activeItem = MENU_IDS.permissionGroup;
          break;
        case AppRoutes.video:
          activeItem = MENU_IDS.menuVideo;
          break;
        case AppRoutes.video_group:
          activeItem = MENU_IDS.menuVideoGroup;
          break;
        case AppRoutes.config_baner:
          activeItem = MENU_IDS.menuBanner;
          break;
        case AppRoutes.config_popup:
          activeItem = MENU_IDS.menuPopup;
          break;
        case AppRoutes.artist:
          activeItem = MENU_IDS.menuArtist;
          break;
        case AppRoutes.artist_photo:
          activeItem = MENU_IDS.menuArtistPhoto;
          break;
        case AppRoutes.artist_post:
          activeItem = MENU_IDS.menuArtistPost;
          break;
        case AppRoutes.artist_reward:
          activeItem = MENU_IDS.menuArtistReward;
          break;
        case AppRoutes.products:
          activeItem = MENU_IDS.menuProducts;
          break;
        case AppRoutes.productCategory:
          activeItem = MENU_IDS.menuProductCategory;
          break;
        case AppRoutes.emojie:
          activeItem = MENU_IDS.menuEmojie;
          break;
        case AppRoutes.gifts:
          activeItem = MENU_IDS.menuGifts;
          break;
        case AppRoutes.greeting_card_log:
          activeItem = MENU_IDS.menuGreetingCardLog;
          break;
        case AppRoutes.order:
        case AppRoutes.order_detail:
          activeItem = MENU_IDS.menuOrder;
          break;
        case AppRoutes.artistsProductCategories:
          activeItem = MENU_IDS.menuArtistsProductCategory;
          break;
        case AppRoutes.user:
        case AppRoutes.user_detail:
          activeItem = MENU_IDS.menuUser;
          break;
        case AppRoutes.configSystem:
          activeItem = MENU_IDS.menuConfigSystem;
          break;
        case AppRoutes.accoutSystem:
          activeItem = MENU_IDS.menuAccountSystem;
          break;
        case AppRoutes.configHome:
          activeItem = MENU_IDS.menuConfigHome;
          break;
        case AppRoutes.notification:
          activeItem = MENU_IDS.menuNotification;
          break;
        case AppRoutes.paymentType:
          activeItem = MENU_IDS.menuPaymentType;
          break;
        case AppRoutes.shippingUnit:
          activeItem = MENU_IDS.menuShippingUnit;
          break;
        case AppRoutes.album:
          activeItem = MENU_IDS.menuAlbum;
          break;
        case AppRoutes.album_video:
          activeItem = MENU_IDS.menuAlbumVideo;
          break;
        case AppRoutes.topic:
          activeItem = MENU_IDS.menuTopic;
          break;
        case AppRoutes.tag:
          activeItem = MENU_IDS.menuTag;
          break;
        case AppRoutes.publishers:
          activeItem = MENU_IDS.menuPublisher;
          break;
        case AppRoutes.audio:
          activeItem = MENU_IDS.menuAudio;
          break;
        case AppRoutes.service:
          activeItem = MENU_IDS.menuService;
          break;
        case AppRoutes.premium_type:
          activeItem = MENU_IDS.menuPremiumType;
          break;
        case AppRoutes.event_ticket:
          activeItem = MENU_IDS.menuEventTicket;
          break;
        case AppRoutes.event:
          activeItem = MENU_IDS.menuEvent;
          break;
        case AppRoutes.event_artist:
          activeItem = MENU_IDS.menuEventArtist;
          break;
        case AppRoutes.history_premium:
          activeItem = MENU_IDS.menuHistoryPremium;
          break;
        case AppRoutes.statistics_artists:
        case AppRoutes.statistics_artists_sale:
        case AppRoutes.statistics_access_video:
          activeItem = MENU_IDS.menuStatisticsArtists;
          break;
        case AppRoutes.statistics_system:
        case AppRoutes.statistics_history_premium:
        // case AppRoutes.statistics_premium_type_buy_report:
          activeItem = MENU_IDS.menuStatisticsSystem;
          break;
        case AppRoutes.video_report:
          activeItem = MENU_IDS.menuVideoReport;
          break;
        case AppRoutes.statistics_premium_type_buy_report:
          activeItem = MENU_IDS.menuStatisticsPayment;
          break;
        case AppRoutes.history_used_coin:
          activeItem = MENU_IDS.menuHistoryUsedCoin;
          break;
        case AppRoutes.audio_report:
          activeItem = MENU_IDS.menuAudioReport;
          break;
        case AppRoutes.audit_logs:
          activeItem = MENU_IDS.menuAuditLogs;
          break;
        case AppRoutes.log_sms_history:
          activeItem = MENU_IDS.menuLogSMShistory;
          break;
        case AppRoutes.present_artist:
          activeItem = MENU_IDS.menuPresentArtist;
          break;
        case AppRoutes.present_audio:
          activeItem = MENU_IDS.menuPresentAudio;
          break;
        case AppRoutes.present_video:
          activeItem = MENU_IDS.menuPresentVideo;
          break;
        default:
          break;
      }
      if (pathLocation.match(/\user-detail/g)) {
        activeItem = MENU_IDS.menuUser;
      }
      setMenuActive(activeItem);
    };

    getMenuActive();
  }, [history.location.pathname]);

  return menuActive;
}

export default useActiveMenu;
