import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./ConfigLayout.scss";

// import Menu from "./Menu";
import { Menu } from "antd";
import useActiveMenu from "hooks/useActiveMenu";

const MenuLeft = ({
  children,
  menus,
  classNameMenuLeft,
  classNameContent,
  className,
}) => {
  const [collapseMenu, setCollapseMenu] = useState(false);
  const history = useHistory();
  const params = useParams();
  const paramArray = Object.keys(params).map((key) => params[key]);
  const [...p] = paramArray;

  const onClick = ({ item }) => {
    const link = item?.props?.link;
    if (typeof link === "function" && paramArray?.length) {
      const url = link(...p);
      history.push(url);
    } else {
      history.push(link);
    }
  };

  const menuActive = useActiveMenu();

  return (
    <div className={classNames("flex h-menuLeft", className)}>
      <div
        className={classNames(
          "bg-F5F5F5 flex-shrink-0 relative",
          classNameMenuLeft
        )}
        style={{
          width: collapseMenu ? 80 : 236,
          transitionProperty: "width",
          transitionDuration: "200ms",
        }}
      >
        <div
          className="absolute bg-gray-500 hover:bg-gray-400 w-24px h-24px transition-all rounded-full text-white flex justify-center items-center -right-10px cursor-pointer top-1/2 z-20"
          onClick={() => setCollapseMenu(!collapseMenu)}
        >
          {!collapseMenu ? (
            <ChevronLeft className="text-20px" />
          ) : (
            <ChevronRight className="text-20px" />
          )}
        </div>
        <div className="w-full h-full overflow-y-auto overflow-x-hidden">
          {/* <Menu menu={menus} collapseMenu={collapseMenu} /> */}
          <Menu
            onClick={onClick}
            selectedKeys={[menuActive]}
            mode="inline"
            items={menus}
            inlineCollapsed={collapseMenu}
          />
        </div>
      </div>
      <div className={classNames("flex-1 h-full", classNameContent)}>
        {children}
      </div>
    </div>
  );
};

MenuLeft.propTypes = {
  children: PropTypes.any,
  isShowTitle: PropTypes.bool,
  isShowMenuLeft: PropTypes.bool,
  menus: PropTypes.any,
  theme: PropTypes.string,
  classNameContent: PropTypes.string,
  classNameMenuLeft: PropTypes.string,
  className: PropTypes.string,
  isShowDetailProject: PropTypes.bool,
};

export default MenuLeft;
