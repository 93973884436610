import i18n from "../i18n";

export const TIME_DEBOUNCE = 300;
export const START_DATE_CODE = "01/01/1900";

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;

export const FORMAT_DATE_VN = "DD/MM/YYYY";
export const FORMAT_DATE_YMD = "YYYYMMDD";

export const LANG_CODE = {
  vi: "vi",
  en: "en",
};

export const LANG = [
  {
    id: "vi",
    name: i18n.t("vietnamese"),
  },
  {
    id: "en",
    name: i18n.t("english"),
  },
];

export const CONFIG_OTP_CODE = {
  EMAIL: 1,
  PHONE: 2,
};

export const GENDER_CODE = {
  MALE: 0,
  FEMALE: 1,
  OTHER: 2,
};

export const GENDER = [
  { value: GENDER_CODE.MALE, label: i18n.t("male") },
  { value: GENDER_CODE.FEMALE, label: i18n.t("female") },
  { value: GENDER_CODE.OTHER, label: i18n.t("other") },
];

export const ACTION_CODE = {
  EDIT: "EDIT",
  CREATE: "CREATE",
  DETAIL: "DETAIL",
  CHECK_PERMISSION: "CHECK_PERMISSION",
};

export const LABEL_COLOR_CODE = {
  GREEN: 0,
  BROWN: 1,
  BLUE: 2,
  ORANGE: 3,
  RED: 4,
  BLACK: 5,
};
export const LABEL_COLOR = [
  { value: LABEL_COLOR_CODE.GREEN, background: "bg-389E0D", color: "#389E0D" },
  { value: LABEL_COLOR_CODE.BROWN, background: "bg-03A89C", color: "#03A89C" },
  { value: LABEL_COLOR_CODE.BLUE, background: "bg-2F54EB", color: "#2F54EB" },
  { value: LABEL_COLOR_CODE.ORANGE, background: "bg-FAAD14", color: "#FAAD14" },
  { value: LABEL_COLOR_CODE.RED, background: "bg-F5222D", color: "#F5222D" },
  { value: LABEL_COLOR_CODE.BLACK, background: "bg-262626", color: "#262626" },
];
export const CODE_LABEL_COLOR = {
  0: "bg-389E0D",
  1: "bg-03A89C",
  2: "bg-2F54EB",
  3: "bg-FAAD14",
  4: "bg-F5222D",
  5: "bg-262626",
};

export const PROCEDURE_CODE = {
  IN_PROGRESS: "0",
  DELAY_PROGRESS: "1",
  NOT_YET_TAKEN_OVER: "2",
  PREPARING_TAKEN_OVER: "3",
  TAKEN_OVER: "4",
};

export const CODE_PROCEDURE = {
  0: "in_progress",
  1: "delay_progress",
  2: "not_yet_taken_over",
  3: "preparing_taking_over",
  4: "taken_over",
};

export const PROCEDURE = [
  {
    key: PROCEDURE_CODE.IN_PROGRESS,
    label: "in_progress",
  },
  {
    key: PROCEDURE_CODE.DELAY_PROGRESS,
    label: "delay_progress",
  },
  {
    key: PROCEDURE_CODE.NOT_YET_TAKEN_OVER,
    label: "not_yet_taken_over",
  },
  {
    key: PROCEDURE_CODE.PREPARING_TAKEN_OVER,
    label: "preparing_taking_over",
  },
  {
    key: PROCEDURE_CODE.TAKEN_OVER,
    label: "taken_over",
  },
];

export const PROPERTY_TYPE_CODE = {
  SHOPHOUSE: "5",
  MASION: "6",
  VILLA: "4",
  TOWNHOUSE: "3",
  LUXURY_BUILDING: "2",
  ORDINARY_BUILDING: "1",
  OTHER: "0",
};

export const CODE_NATIONALITY = {
  0: "text.foreigner",
  1: "text.vietnam",
};

export const CODE_GENDER = {
  0: "male",
  1: "female",
  2: "Other",
};

export const ROLE_CODE = {
  0: "member",
  1: "householder",
};

export const MEMBER_ROLE = {
  MEMBER: 0,
  HOUSEHOLDER: 1,
};

export const STRUCT_TYPE_CODE = {
  0: i18n.t("project.area"),
  1: i18n.t("project.lot"),
  2: i18n.t("project.building"),
  3: i18n.t("project.floor"),
};

export const STRUCT_TYPE = [
  { value: 0, label: i18n.t("project.area") },
  { value: 1, label: i18n.t("project.lot") },
  { value: 2, label: i18n.t("project.building") },
  { value: 3, label: i18n.t("project.floor") },
];

export const PERMISSION_CODE = {
  0: i18n.t("management"),
  1: i18n.t("permission_employee"),
};

export const PERMISSION = [
  { value: 1, label: i18n.t("management") },
  { value: 0, label: i18n.t("permission_employee") },
];

export const NOTIFICATION_TYPE = {
  1: "password",
  2: "birthday",
  3: "propose_member",
  4: "edit_member",
  5: "propose_active",
  6: "cancel_active",
};

export const REQUEST_TYPE_CODE = {
  0: i18n.t("recommend.request.type.add"),
  1: i18n.t("recommend.request.type.edit"),
  2: i18n.t("recommend.request.type.active"),
  3: i18n.t("recommend.request.type.deactive"),
  4: i18n.t("recommend.request.type.other"),
};

export const REQUEST_TYPE = [
  {
    value: 0,
    label: i18n.t("recommend.request.type.add"),
  },
  {
    value: 1,
    label: i18n.t("recommend.request.type.edit"),
  },
  {
    value: 2,
    label: i18n.t("recommend.request.type.active"),
  },
  {
    value: 3,
    label: i18n.t("recommend.request.type.deactive"),
  },
  {
    value: 4,
    label: i18n.t("recommend.request.type.other"),
  },
];

export const PROPOSE_TYPE = {
  0: i18n.t("propose.pending"),
  1: i18n.t("propose.approved"),
  2: i18n.t("propose.rejected"),
};

export const TYPE_SEND_MAIL = {
  SMTP: 1,
  API: 0,
};

export const TYPE_VIDEO_GROUP = {
  VIDEO_SET: "2", // video bộ
  VIDEO_ODD: "1", // video lẻ
};

// 0: Chờ duyệt, 1: Hiển thị, 2: xóa, 3: Duyệt
export const STATUS_DEFAULT = [
  {
    value: "0",
    label: "Chờ duyệt",
  },
  {
    value: "1",
    label: "Hiển thị",
  },
  {
    value: "2",
    label: "Xóa",
  },
  {
    value: "3",
    label: "Duyệt",
  },
];
export const VIDEO_RECOMMENED = [
  {
    value: "1",
    label: "Nổi bật",
  },
  {
    value: "0",
    label: "Không nổi bật",
  },
];
export const ARTIST_POPULAR = [
  {
    value: "1",
    label: "Thịnh hành",
  },
  {
    value: "0",
    label: "Không thịnh hành",
  },
];
export const STATUS_DEFAULT_CREATE = [
  {
    value: "0",
    label: "Chờ duyệt",
  },
  {
    value: "1",
    label: "Hiển thị",
  },
  {
    value: "3",
    label: "Duyệt",
  },
];

//0: chờ duyệt, 1: duyệt, 2: xóa, 3: đang push, 4: pushed, 5: chờ push
export const STATUS_NOTIFICATION = [
  {
    value: "0",
    label: "Chờ duyệt",
  },
  {
    value: "1",
    label: "Duyệt",
  },
  {
    value: "2",
    label: "Xóa",
  },
  {
    value: "5",
    label: "Chờ push",
  },
];

//0: chờ duyệt, 1: duyệt, 2: xóa, 3: đang push, 4: pushed, 5: chờ push
export const STATUS_SEARCH_NOTIFICATION = [
  {
    value: "0",
    label: "Chờ duyệt",
  },
  {
    value: "1",
    label: "Duyệt",
  },
  {
    value: "2",
    label: "Xóa",
  },
  {
    value: "3",
    label: "Đang push",
  },
  {
    value: "4",
    label: "Pushed",
  },
  {
    value: "5",
    label: "Chờ push",
  },
];
export const STATUS_NOTIFICATION_CREATE = [
  {
    value: "0",
    label: "Chờ duyệt",
  },
  {
    value: "1",
    label: "Duyệt",
  },
  {
    value: "5",
    label: "Chờ push",
  },
];

export const EMOJIE_TYPE = [
  {
    value: "1",
    label: "Emoji cha comment",
  },
  {
    value: "2",
    label: "Emoji con comment",
  },
  {
    value: "3",
    label: "Emoji livestream ",
  },
];

//1: nạp tiền, 2: subcription, 3: mua gói lẻ
export const PURCHASE_TYPE = [
  {
    value: "1",
    label: "Nạp tiền",
  },
  {
    value: "2",
    label: "subcription",
  },
  {
    value: "3",
    label: "Mua gói lẻ",
  },
];

//nền tảng: null = all,  2: Android, 1: ios (droplist)
export const PLATFORM = [
  {
    value: "null",
    label: "ALL",
  },
  {
    value: "2",
    label: "Android",
  },
  {
    value: "1",
    label: "Ios",
  },
];

export const TYPE_CONFIG_HOME_ENUM = {
  ARTIST: "1",
  VIDEO: "2",
  BANNER: "3",
  LIVE: "4",
  AUDIO: "5",
  ALBUMS: "7",
  ALBUM_DETAIL: "8",
  CATEGORY_AUDIO: "9",
  CATEGORY_VIDEO: "10",
};

export const TYPE_CONFIG_HOME_SEARCH = [
  {
    value: "1",
    label: "Nghệ sĩ",
  },
  {
    value: "2",
    label: "Video",
  },
  {
    value: "3",
    label: "Banner",
  },
  {
    value: "4",
    label: "Live",
  },
  {
    value: "5",
    label: "Audio",
  },
  {
    value: "6",
    label: "Bảng xếp hạng",
  },
  {
    value: "7",
    label: "Danh sách Albums",
  },
  {
    value: "8",
    label: "Chi tiết Albums",
  },
  {
    value: "9",
    label: "Danh mục audio",
  },
  {
    value: "10",
    label: "Danh mục video",
  },
  {
    value: "11",
    label: "Danh sách audio dọc",
  },
  {
    value: "12",
    label: "Danh sách video dọc",
  },
];

export const TYPE_CONFIG_HOME = [
  {
    value: "1",
    label: "Nghệ sĩ",
  },
  {
    value: "2",
    label: "Video",
  },
  {
    value: "3",
    label: "Banner",
  },
  {
    value: "4",
    label: "Live",
  },
  {
    value: "5",
    label: "Audio",
  },
  {
    value: "6",
    label: "Bảng xếp hạng",
  },
  {
    value: "7",
    label: "Danh sách Albums",
  },
  {
    value: "9",
    label: "Danh mục audio",
  },
  {
    value: "10",
    label: "Danh mục video",
  },
  {
    value: "11",
    label: "Danh sách audio dọc",
  },
  {
    value: "12",
    label: "Danh sách video dọc",
  },
];

export const TYPE_CONFIG_HOME_DATA = [
  {
    value: "1",
    label: "Nghệ sĩ",
  },
  {
    value: "2",
    label: "Video",
  },
  {
    value: "3",
    label: "Banner",
  },
  {
    value: "4",
    label: "Live",
  },
  {
    value: "5",
    label: "Audio",
  },
  {
    value: "7",
    label: "Danh sách Albums",
  },
  {
    value: "8",
    label: "Chi tiết Albums",
  },
  {
    value: "9",
    label: "Danh mục audio",
  },
  {
    value: "10",
    label: "Danh mục video",
  },
];

export const TYPE_VIDEO = {
  BIZFLY: "bizfly",
  YOUTUBE: "youtube",
};

export const BLOCK_TYPE = [
  {
    value: "1",
    label: "Endpoint",
  },
  {
    value: "2",
    label: "Dữ liệu data",
  },
];
export const HOME_POSITISION = [
  {
    value: "1",
    label: "Màn Home",
  },
  {
    value: "3",
    label: "Màn Home Version cũ",
  },
  {
    value: "2",
    label: "Màn danh sách theo danh mục",
  },
];

export const DIMENTION_IMAGE = [
  {
    value: "400x480",
    x: 400,
    y: 480,
    label: "400x480",
  },
  {
    value: "400x599",
    x: 400,
    y: 599,
    label: "400x599",
  },
  {
    value: "400x400",
    x: 400,
    y: 400,
    label: "400x400",
  },
  {
    value: "400x248",
    x: 400,
    y: 248,
    label: "400x248",
  },
  {
    value: "400x183",
    x: 400,
    y: 183,
    label: "400x183",
  },
  {
    value: "400x334",
    x: 400,
    y: 334,
    label: "400x334",
  },
  {
    value: "1200x665",
    x: 1200,
    y: 665,
    label: "1200x665",
  },
];

// '165x198x400x480'
// '145x174x400x480'
// '100x120x400x480'
// '170x254.575x400x599'
// '165x137.775x400x334'
// '47x47x400x400'
// '200x240x400x480'

export const DIMENTION_IMAGE_V2 = [
  {
    value: "165x198x400x480",
    label: "Banner dọc (400x480) width 165",
  },
  {
    value: "145x174x400x480",
    label: "Banner dọc (400x480) width 145",
  },
  {
    value: "100x120x400x480",
    label: "Banner dọc (400x480) width 100",
  },
  {
    value: "170x254.575x400x599",
    label: "Banner dọc (400x599) width 170",
  },
  {
    value: "165x137.775x400x334",
    label: "Banner ngang (400x334) width 165",
  },
  {
    value: "47x47x400x400",
    label: "Banner vuông (400x400) width 47",
  },
  {
    value: "200x240x400x480",
    label: "Banner dọc (400x480) width 200",
  },

  // {
  //   value: "400x599",

  //   label: "400x599",
  // },
  // {
  //   value: "400x400",
  //   label: "400x400",
  // },
  // {
  //   value: "400x248",
  //   label: "400x248",
  // },
  // {
  //   value: "400x183",

  //   label: "400x183",
  // },
  // {
  //   value: "400x334",
  //   label: "400x334",
  // },
  // {
  //   value: "1200x665",
  //   label: "1200x665",
  // },
];

export const TYPE_GIFT = [
  {
    value: "1",
    label: "Comment cha",
  },
  {
    value: "2",
    label: "Comment con",
  },
  {
    value: "3",
    label: "Live stream",
  },
  {
    value: "4",
    label: "Short video",
  },
  {
    value: "5",
    label: "Audio",
  },
  {
    value: "6",
    label: "Nghệ sĩ",
  },
  {
    value: "7",
    label: "Thiệp",
  },
];
