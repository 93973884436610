import classNames from "classnames";
import FormChangePassword from "components/Form/FormChangePassword/FormChangePassword";
import Modal from "components/Modal/Modal";
import Utils from "helpers/Utils";
import { useClickOutside } from "hooks/useClickOutside";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AUTH_ACTION from "redux/user/actions";

function UserMenu() {
  const [openMenu, setOpenMenu] = useState(false);
  const [openChangePass, setOpenChangePass] = useState(false);
  const menuRef = useRef();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const infoUser = useSelector((state) => state.user.infoUser) || {};

  const logout = () => {
    AUTH_ACTION.onLogoutApi().then((res) => {
      if (res) {
        dispatch(AUTH_ACTION.onLogout(history));
      }
    });
  };

  const menus = [
    {
      title: "Đổi mật khẩu",
      link: "",
      onClick: () => {
        setOpenChangePass(true);
      },
    },
    {
      title: t("log_out"),
      link: "",
      onClick: () => {
        logout();
      },
    },
  ];

  useClickOutside(menuRef, () => {
    setOpenMenu(false);
  });

  const handleClickItem = (onClickItem) => {
    setOpenMenu(false);
    onClickItem && onClickItem();
  };

  const handleCancel = () => {
    setOpenChangePass(false);
  };

  const onChangePassword = (body) => {
    AUTH_ACTION.changePassword(body).then((res) => {
      if (res) setOpenChangePass(false);
    });
  };

  return (
    <div>
      <div
        ref={menuRef}
        className="flex items-center gap-8px cursor-pointer relative leading-48px"
      >
        <div
          className="flex items-center gap-8px select-none"
          onClick={() => setOpenMenu(!openMenu)}
        >
          <div className="w-24px h-24px bg-237804 rounded-full overflow-hidden">
            <img
              src={Utils.getAvatar(null)}
              alt=""
              className="object-cover w-full h-full"
            />
          </div>
          <p className="text-white">{infoUser?.fullname}</p>
        </div>

        {openMenu && (
          <div className="absolute z-20 top-topMenuUser -right-12px shadow-menuUser bg-white rounded-8px py-8px w-219px leading-normal">
            {menus.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={() => handleClickItem(item.onClick)}
                  className={classNames(
                    "px-16px py-12px text-14px text-black opacity-85 hover:text-white transition-all",
                    {
                      "hover:bg-blue-400": true,
                    }
                  )}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Modal
        visible={openChangePass}
        title={"Thay đổi mật khẩu"}
        footer={null}
        onCancel={handleCancel}
        centered
        width={350}
      >
        <FormChangePassword
          onClose={handleCancel}
          onChangePassword={onChangePassword}
        />
      </Modal>
    </div>
  );
}

UserMenu.propTypes = {};

export default UserMenu;
