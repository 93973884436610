import { LANG_CODE } from "app-constants";
import { AppRoutes } from "app-constants/AppRoutes";
import {
  notifyError,
  notifySuccess,
} from "components/Notification/Notification";
import { getMessageErr } from "helpers/messageError";
import { getUserName } from "helpers/storage";
import i18n from "i18n";
import { appI18n } from "i18n";
import apiClient from "services/apiClient";
import { actionTypes } from "../actionTypes";
import Utils, { checkResponse } from "../../helpers/Utils";

const handleError = (error) => {
  const messageErr = getMessageErr(error);
  notifyError(messageErr?.message);
  return Promise.reject(error);
};

const handleSaveSecretKey = (secretKey, refreshToken) => {
  localStorage.setItem("secretKey", secretKey);
  localStorage.setItem("refreshToken", refreshToken);
};

const onLogin = (username, password, history) => async (dispatch) => {
  try {
    const response = await apiClient.post("/user/login", {
      username,
      password,
    });
    const data = response?.data;
    if (checkResponse(data)) {
      handleSaveSecretKey(data?.secretKey);
      notifySuccess(appI18n.t("login_success"));
      dispatch(setInfoLogin(data));
      dispatch(getInfoUser());
      history.push(AppRoutes.category);
    } else {
      notifyError(data?.errors);
    }
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getInfoUser = () => async (dispatch) => {
  try {
    const response = await apiClient.post("/user/userinfo");
    if (response?.data) {
      const user = response?.data;
      dispatch(setInfoUser(user));
    }
    return response?.data;
  } catch (error) {
    return handleError(error);
  }
};

const forgotPassword = async (username) => {
  try {
    return await apiClient.post("/auth/web/forget-password", { username });
  } catch (error) {
    handleError(error);
  }
};

const changePassword = async (body) => {
  try {
    const response = await apiClient.post("/user/changepassword", body);

    if (response?.status) {
      notifySuccess(
        Utils.capitalizeFirstLetter("Thay đổi mật khẩu thành công")
      );
      return true;
    }
    notifyError(Utils.capitalizeFirstLetter(response?.errors[0]));
    return false;
  } catch (error) {
    handleError(error);
  }
};

const onLogoutApi = async (body) => {
  try {
    const response = await apiClient.post("/user/logout", body);

    if (response?.data) {
      return true;
    }
    notifyError(Utils.capitalizeFirstLetter(response?.errors[0]));
    return false;
  } catch (error) {
    handleError(error);
  }
};

const onLogout = (history) => (dispatch) => {
  ["secretKey", "refreshToken"].forEach((item) => {
    localStorage.removeItem(item);
  });
  dispatch(setInfoLogin(null));
  dispatch(setInfoUser(null));
  history.push(AppRoutes.login);
};

const updateUser = async (dataUpdate) => {
  try {
    const response = await apiClient.put("/auth/web/update-user", dataUpdate);
    if (response?.result === 1) {
      notifySuccess(i18n.t("editing_personal_information_successfully"));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

export const setInfoLogin = (info) => ({
  type: actionTypes.SET_INFO_LOGIN,
  payload: info,
});

export const setInfoUser = (info) => ({
  type: actionTypes.SET_INFO_USER,
  payload: info,
});

const AUTH_ACTION = {
  onLogin,
  getInfoUser,
  forgotPassword,
  onLogout,
  updateUser,
  changePassword,
  onLogoutApi,
};

export default AUTH_ACTION;
