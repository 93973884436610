import NotificationCount from "components/NotificationCount/NotificationCount";
import UserMenu from "components/UserMenu/UserMenu";
import logo_vmg from "resources/images/logo_vmg.png";

function MenuTop() {
  return (
    <div className="h-48px bg-blue-400 text-white flex justify-between pl-16px pr-28px select-none">
      {/* left */}
      <div className="flex items-center">
        <ul className="flex items-center gap-40px">
          <img src={logo_vmg} alt="logo" style={{ width: 100 }} />
        </ul>
      </div>
      {/* right */}
      <div className="flex items-center gap-30px">
        <NotificationCount />
        <UserMenu />
      </div>
    </div>
  );
}

export default MenuTop;
